import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Team from "../components/team"
import ContactForm from "../components/contactForm"
import Hero from "../components/hero"
import Content from "../components/content"
import Numbers from "../components/numbers"

/**
 * GraphQL Query to retrieve page data
 *
 * @return {Object}
 */
export const aboutPageQuery = graphql`
  {
    site {
      siteMetadata {
        email
        phone
      }
    }
    hero: file(relativePath: { eq: "pages/about/header-home-erick.jpg" }) {
      sharp: childImageSharp {
        fluid(
          quality: 100
          maxWidth: 1920
          duotone: { highlight: "#15181f", shadow: "#15181f", opacity: 55 }
        ) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

/**
 * About Page Component
 * This component is used to generate the About page
 *
 * @param {Object} props
 */
const AboutPage = ({ data }) => {
  return (
    <Layout isSecondary={true}>
      <SEO title="Acerca" description="" />

      <Hero
        type="simple"
        tagline="Lic. Erick Claveria - Abogado de Guatemala en San Francisco California."
        title="Acerca de"
        background={data.hero.sharp.fluid}
      />

      <Content
        tagline="La oficina del licenciado Erick Estuardo Claveria Roldan fue fundada en el año 2016"
        title="En la ciudad de San Rafael California."
      >
        <p>
        Al día de hoy tiene el privilegio de servirle a todos los guatemaltecos que residen
        en San Francisco California, a lo largo  del área de la bahía y la unión americana,
        esta compuesta por un grupo de abogados y notarios guatemaltecos de alto nivel y profesionalismo.
        </p>
        <p>
        El licenciado Erick Estuardo Claveria Roldan es Abogado y Notario Guatemalteco graduado
        en la Universidad de San Carlos De Guatemala en el año 2007, colegiado activo del Colegio
        de Abogados y Notarios de Guatemala y debidamente registrado en El Archivo General de Protocolos
        del Organismo Judicial de la Republica de Guatemala y en el Registro General de La Propiedad
        de Guatemala.
        </p>
        <p>
        Nuestro objetivo primordial es brindarle asesoría jurídica de las leyes guatemaltecas
        a todos los chapines que residen en Estados Unidos de América, darles una solución real
        y efectiva en el menor tiempo posible a todos sus asuntos legales pendientes de resolver
        en Guatemala, la elaboración de mandatos y otros documentos legales como escrituras públicas
        y actas notariales, que son elaborados llenando estrictamente cada uno de los requisitos
        de conformidad con las leyes Guatemaltecas, dándoles a todos aquellos guatemaltecos que residen
        en San Francisco California, a lo largo del área de la bahía y la unión americana que deseen
        utilizar nuestros servicios la  certeza  que su asunto legal será resuelto con profesionalismo
        altamente calificado y en forma pronta y cumplida.
        </p>
      </Content>

      <Numbers />

      {/*<Team type="simple" />*/}

      <ContactForm
        email={data.site.siteMetadata.email}
        phone={data.site.siteMetadata.phone}
      />
    </Layout>
  )
}

export default AboutPage
