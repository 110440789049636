import React from "react"
import LawIcon from "./icons/LawIcon"
import LawyerIcon from "./icons/LawyerIcon"
import GavelIcon from "./icons/GavelIcon"
import PolicyIcon from "./icons/PolicyIcon"

/**
 * Numbers component
 * This component is used to show blocks with percentages
 *
 * @param {Object} props
 */
const Numbers = () => {
  return (
    <section className="numbers-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="numbers__block">
              <LawIcon className="numbers__icon" />
              <h3 className="numbers__percentage">95%</h3>
              <p className="numbers__description">Casos ganados</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="numbers__block last">
              <PolicyIcon className="numbers__icon" />
              <h3 className="numbers__percentage">14</h3>
              <p className="numbers__description">años de experiencia</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="numbers__block">
              <LawyerIcon className="numbers__icon" />
              <h3 className="numbers__percentage">6</h3>
              <p className="numbers__description">Abogados</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 col-sm-12">
            <div className="numbers__block">
              <GavelIcon className="numbers__icon" />
              <h3 className="numbers__percentage">+7000</h3>
              <p className="numbers__description">guatemaltecos asesorados</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Numbers
